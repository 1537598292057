/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');
require('../album/album.css');
require('../sticky-footer/sticky-footer.css');

import $ from 'jquery';
import 'bootstrap'; // adds functions to jQuery

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');
// import 'bootstrap';

// console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
